"use client";

import * as Sentry from "@sentry/nextjs";
import { APIProvider as GoogleMapAPIPRovider } from "@vis.gl/react-google-maps";
import { Colors } from "components/colors";
import { getApps, initializeApp } from "firebase/app";
import { getMessaging, getToken } from "firebase/messaging";
import { APIError } from "libs/api/client";
import Head from "next/head";
import { useRouter } from "next/navigation";
import { ReactNode, useEffect } from "react";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import styled, { createGlobalStyle } from "styled-components";
import { SWRConfig } from "swr";

type AppRouterAppProviderProps = {
  children: ReactNode;
  configureSWRForTest?: boolean;
};

export const AppRouterAppProvider = ({
  children,
  configureSWRForTest = false,
}: AppRouterAppProviderProps) => {
  if (getApps().length === 0) {
    initializeApp({
      apiKey: "AIzaSyCaefgtpThXSdbjmQH59LuxIRLa4MB8kgA",
      authDomain: "crew-express-5d634.firebaseapp.com",
      databaseURL: "https://crew-express-5d634.firebaseio.com",
      projectId: "crew-express-5d634",
      storageBucket: "crew-express-5d634.appspot.com",
      messagingSenderId: "349977201416",
      appId: process.env.NEXT_PUBLIC_FIREBASE_APP_ID,
      measurementId: process.env.NEXT_PUBLIC_FIREBASE_MEASUREMENT_ID,
    });
  }

  useEffect(() => {
    const f = async () => {
      // ブラウザがServiceWorkerに対応していない場合は何もしない
      if (!("serviceWorker" in navigator)) {
        return;
      }
      const registration = await navigator.serviceWorker.register(
        `/firebase-messaging-sw.js?appId=${process.env.NEXT_PUBLIC_FIREBASE_APP_ID}&measurementId=${process.env.NEXT_PUBLIC_FIREBASE_MEASUREMENT_ID}`,
      );
      const messaging = getMessaging();
      await getToken(messaging, {
        serviceWorkerRegistration: registration,
      }).catch((error) => {
        // パーミッションが許可されていない場合は何もしない
        if (error.code !== "messaging/permission-blocked") {
          Sentry.captureException(error);
        }
      });
    };
    f();
  }, []);

  const router = useRouter();
  return (
    <>
      <Head>
        <title>DeliveryX</title>

        <link rel="icon" type="image/svg+xml" href="/favicon.svg" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      </Head>
      <GlobalStyle />
      <SWRConfig
        value={
          configureSWRForTest
            ? {
                dedupingInterval: 0,
                provider: () => new Map(),
              }
            : {
                onError: (err) => {
                  if (err instanceof APIError) {
                    if (
                      err.status === 401 &&
                      err.type === "ApiError::Unauthorized"
                    ) {
                      router.push("/login");
                    } else if (
                      err.type === "ApiError::DeliveryClient::AccountLocked"
                    ) {
                      router.push("/account_locked");
                    }
                  }
                },
              }
        }
      >
        {/* GoogleMapを利用するページが複数存在するが、GoogleMapWrapperはリロードを挟まずに複数回実行するとエラーになるため、アプリ全体で共通化する*/}
        <GoogleMapAPIPRovider
          apiKey={process.env.NEXT_PUBLIC_GOOGLE_MAP_API_KEY || ""}
          libraries={["places"]}
        >
          <StyledToastContainer
            position="bottom-center"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="dark"
          />
          {children}
        </GoogleMapAPIPRovider>
      </SWRConfig>
    </>
  );
};

const GlobalStyle = createGlobalStyle`
  body {
    background-color: ${Colors.primaryBase};
    font-family: Noto Sans CJK JP;
    font-style: normal;
    font-weight: normal;
  }

  @keyframes shimmer {
    0% {
      background-position: -1000px 0;
    }
    100% {
      background-position: 1000px 0;
    }
  }
`;

const StyledToastContainer = styled(ToastContainer)`
  // https://styled-components.com/docs/faqs#how-can-i-override-styles-with-higher-specificity
  &&&.Toastify__toast-container {
  }
  .Toastify__toast {
    background-color: ${Colors.primaryBase};
  }
  .Toastify__toast-body {
    color: ${Colors.white100};
  }
  .Toastify__close-button {
    color: ${Colors.white100};
  }
  .Toastify__progress-bar {
  }
`;
